/*global require: false */

require(['jquery', 'core', 'validate', 'unobtrusive'], function ($) {
    'use strict';

    $('form.limit-submit').on('submit', function () {

        var $form = $(this);
        if ($form.valid()) {
            var $submit = $form.find('.button');
            var $links = $form.find('a');
            var $loader = $('.loader');
            var $loaderblur = $('.loader-blur');
            var $doormat = $('.doormat__wrapper');

            if ($loader.length && $loaderblur.length) {
                $loader.toggle();
                $loaderblur.toggle();
                $doormat.toggle();
            }

            if ($submit.length) {
                $.each($submit, function () {
                    $(this).attr('disabled', 'disabled');
                    $(this).removeClass('button-primary').addClass('button-disabled');
                });
            }

            if ($links.length) {
                $.each($links, function () {
                    $(this).removeAttr('href');
                });
            }
        }
    });

});
define("forms", function(){});

